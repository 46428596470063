$(document).ready(function() {
    var lastScroll = 0;

    $('.closeModal').click(function(){
        $('body').removeClass('locked');
        $('#modal').removeClass('active');
        $('html, body').animate({
            scrollTop: lastScroll
        }, 100);
    });

    $('.jsModal').click(function(){
        lastScroll =  $(window).scrollTop();
        $('body').addClass('locked');
        $('#modal').addClass('active');
    });

    $('.btnJs').click(function(){
        if($('#'+$(this).attr('data-target')).hasClass('d-none')){
            $('#'+$(this).attr('data-target')).removeClass('d-none');
            $(this).find('span').html($(this).attr('data-hide-str'));
        }else{
            $(this).find('span').html($(this).attr('data-show-str'));
            $('#'+$(this).attr('data-target')).addClass('d-none');

        }
    });
});
